<template>
<div>
    <transition name="modal" v-if="showModal" >
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <button  @click="showModal=false" class="close-button">
                    <i class="gg-close" ></i>
                    </button>
                    <div class="modal-body">
                        <slot name="body">
                            At A-CREATION we’re strong of three years of experience in blockchain development. We have several projects in the field among which the 
                            <a href="http://digitalart-museum.com/" target="_blank" rel="noopener noreferrer">DigitalArt-Museum.com</a> and Sexy-NFT.com.<br> <br>
                            We are currenlty building our own marketplace for NFTs. It will be a userfriendly platform for you to create, display, buy, sell and transfer your NFTs.                            
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <button @click="showModal=true" class="open-button"><span> Read more + </span></button>
</div>
</template>
<script>
export default {
  name: 'PopUp2',
  props: {
  },
  data() { 
      return {
        showModal : false,
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import url('https://css.gg/close.css');

.close-button {
    float: right;
    border: none;
    background-color: #212121;
    color:#ffff;
}

.open-button {
    border: none;
    background-color: #212121;
    color:#ffff;
    border-radius: 20px;
    height: 22px;
    span {
        font-size: 13px;
        padding: 10px;
    }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 40vh;
  @media screen and (min-width : 1024px) {
    width: 50vh;
  }
  margin: 0px auto;
  padding: 20px 10px;
  background-color: #212121;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  text-align: center;
  line-height: 150%;
  font-size: 17px;


  @media screen and (max-height: 2000px ) {
      font-size: 20px;
    }
    @media screen and (max-height: 900px ) {
      font-size: 20px;
    }
    @media screen and (max-height: 737px ) {
      font-size: 15px;
    }

}


/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity:1;
}

.modal-leave-active {
  opacity: 1;
}
</style>
