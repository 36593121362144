<template>
<div>
    <transition name="modal" v-if="showModal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <button @click="showModal=false" class="close-button">
                        <i class="gg-close"></i>
                    </button>
                    <div class="modal-body">
                        <slot name="body">

                            <strong><u>A-Creation</u></strong>

                            <br>

                            A-Creation is a creative brand creator and brand manager.
                            <br>

                            <hr class="dashed">


                            <strong><u>General enquiries</u></strong>
                            <br>
                            contact@ananas-creation.com

                           <br>
                            Contact us on <a href="https://www.instagram.com/ananas.creations" target="_blank" rel="noopener noreferrer"><span style="color: #262626;">instagram</span></a>.

                            <br>
                            <hr class="dashed">
                        

                            © All rights reserved, A-CREATION.

                            

                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <button @click="showModal=true" class="open-button"> <img src="test-button.svg"/></button>
</div>
</template>

<script>
export default {
    name: 'PopUp4',
    props: {},
    data() {
        return {
            showModal: false,
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
@import url('https://css.gg/close.css');

.close-button {
    float: right;
    border: none;
    background-color: #cf0000;
    color: #000000;
}

.open-button {
  position: absolute;
    border: none;
    padding: 0px;
    border-radius: 50px;
    height: 30px;
    min-width: 30px;
    font-family: Ananaas;
    
    left : 10px;
    top : 10px;
    

}

hr.dashed {
    border-top: 1px solid #000;

}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 40vh;

    @media screen and (min-width : 1024px) {
        width: 50vh;
    }

    margin: 0px auto;
    padding: 20px 10px;
    background-color: #cf0000;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica,
    Arial,
    sans-serif;
}

.modal-header h3 {
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
    text-align: center;
    line-height: 150%;
    font-size: 12px;
    color: #000000;


  @media screen and (max-height: 2000px ) {
      font-size: 20px;
    }
    @media screen and (max-height: 900px ) {
      font-size: 20px;
    }
    @media screen and (max-height: 737px ) {
      font-size: 15px;
    }

}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 1;
}

.modal-leave-active {
    opacity: 1;
}
</style>
