<template>
<div>
    <loading-page v-if="!Display3DPage" v-on:change-page="Display3DPage = true" v-bind:status="loaded" v-bind:renderer="renderStatus" class="LoadingPage"></loading-page>
    <pop-up-4 :class="{ hidden : !Display3DPage}"></pop-up-4>
    <div id="info" :class="{ hidden : active_el != 1 || !Display3DPage, fds : active_el == 1}">ANANAS CREATION</div>

    <div id="container">

        <div class="three w-embed"><canvas class="webgl"></canvas></div>

        <div class="SliceInside block1" :class="{ hidden : active_el != 2  || !Display3DPage , fds : active_el == 2 }">
            <b-row>
                <div>
                    <h2> Monetize your audience. </h2>
                </div>
            </b-row>
            <b-row>
                <div>
                    <img src="illustrations/undraw_fashion_photoshoot.svg" alt="" class="illustration">
                </div>
            </b-row>
            <b-row>
                <div>
                    <p>At A-Creation, we help you to capitalise on your audience by <span style="color: #f8a028;">creating a brand</span>.</p>
            
                    <p>--------</p>
     
                    <p> Monetize and grow your audience while providing <span style="color: #f8a028;">entertaining</span> and <span style="color: #f8a028;">engaging content</span> for your community. </p>
                </div>
            </b-row>
            <b-row style="padding-top : 10px">
                <pop-up-1></pop-up-1>
            </b-row>
        </div>

        <div class="SliceInside block2" :class="{ hidden : active_el != 3 || !DisplayBlocks || !Display3DPage, fds : active_el == 3}">
            <b-row>
                <div>
                    <h1>We make it yours.</h1>
                </div>
            </b-row>
            <b-row>
                <div>
                    <p>One <u>brand</u>, one <u>experience</u>, <br> one <u>purpose</u>. <br> <br>
                        We help you to create an attractive and successful brand thanks to its <span style="color: #f8a028;">unique identity</span>.</p>
                </div>
            </b-row>
            <b-row>
                <div>
                    <img src="illustrations/undraw_My_universe.svg" alt="" class="illustration">
                </div>
            </b-row>
            <b-row>
                <div>
                    <p>Customer <span style="color: #f8a028;">time is precious</span>, we support you through the creation of groundbreaking products and magical experiences.</p>
                </div>
            </b-row>
        </div>

        <div class="SliceInside block3" :class="{ hidden : active_el != 4 || !DisplayBlocks || !Display3DPage, fds : active_el == 4}">
            <b-row>
                <div>
                    <h1>Branding.</h1>
                </div>
            </b-row>
            <b-row>
                <div>
                    <p>A brand is more than just a logo or a product. A brand is a purpose, a character, an ahesthetic, a vision that forges a <span style="color: #f8a028;">meaningful identity</span>. </p>
                </div>
            </b-row>
            <b-row>
                <div>
                    <p class="quote"><br> <br> "If you don’t give people a story to talk about, they’ll define your brand’s story for you." <strong> David Brier</strong> <br> <br> <br></p>

                </div>
            </b-row>
            <b-row>
                <div>
                    <p>We build strategies that connects your identity with your business expectations. Ultimately, we want your brand's voice and personality to leverage real <span style="color: #f8a028;">market opportunities</span>.</p>
                </div>
            </b-row>
        </div>

        <div class="SliceInside block4" :class="{ hidden : active_el != 5 || !DisplayBlocks || !Display3DPage, fds : active_el == 5}">
            <b-row>
                <div>
                    <h1>Digital age.</h1>
                </div>
            </b-row>
            <b-row>
                <div>
                    <p>In these disruptive times, we need to think outside the box to provide people with unique digital experiences.
                        A-Creation develops beautiful <span style="color: #f8a028;">immersive</span> and <span style="color: #f8a028;">interactive</span> e-commerce websites able to capture the attention and therefore to increase conversions.</p>
                </div>
            </b-row>
            <b-row>
                <div>
                    <img src="illustrations/undraw_building_websites.svg" alt="" class="illustrationA">
                </div>
            </b-row>
            <b-row>
                <div>
                    <p>With your brand identity in mind, we garantee <span style="color: #f8a028;">high-performance</span> websites and <span style="color: #f8a028;">pixel-perfect</span> results.</p>
                </div>
            </b-row>
            <b-row>
                <div>
                    <p>We also develop <span style="color: #f8a028;">NFT marketplaces</span>(See below &darr;). </p>
                </div>
            </b-row>
        </div>

        <div class="SliceInside block5" :class="{ hidden : active_el != 6 || !DisplayBlocks || !Display3DPage, fds : active_el == 6}">
            <b-row>
                <div>
                    <h1>Shopping experience.</h1>
                </div>
                <b-row>
                    <div>
                        <p>While building responsive websites, we focus on <span style="color: #f8a028;">mobile experience</span> to ensure your customers a memorable time on your website.</p>
                    </div>
                </b-row>
            </b-row>
            <b-row>
                <div>
                    <img src="illustrations/undraw_mobile_ux.svg" alt="" class="illustration">
                </div>
            </b-row>
            <b-row>
                <div>
                    <p>We keep the <span style="color: #f8a028;">customer informed</span> on a regular basis from purchase to product reception.
                        Finally, if the customer wishes to return or exchange their product, we offer them a <span style="color: #f8a028;">free</span> and <span style="color: #f8a028;">easy</span> return.</p>
                </div>
            </b-row>
            <b-row style="padding-top : 10px">
                <pop-up-3></pop-up-3>
            </b-row>
        </div>

        <div class="SliceInside block6" :class="{ hidden : active_el != 7 || !DisplayBlocks || !Display3DPage, fds : active_el == 7}">
            <b-row>
                <div>
                    <h1>Blockchain & NFT.</h1>
                </div>
            </b-row>
            <b-row>
                <div>
                    <p>Blockchain is a very promising technology, which brings more trust in the digital world. NFTs are dematerialised, unique and identifiable property titles protected by the <span style="color: #f8a028;">blockchain</span>.</p>
                </div>
            </b-row>
            <b-row>
                <div>
                    <p>A-Creation introduces you in the NFT market through an existing or a <span style="color: #f8a028;">custom-made</span> marketplace.</p>
                </div>
            </b-row>
            <b-row>
                <div>
                    <img src="illustrations/undraw_artist_b4rc.svg" alt="" class="illustrationA"> </div>
            </b-row>
            <b-row>
                <div>
                    <p>We create NFTs from illustrations, videos, animations, etc.</p>
                </div>
            </b-row>
            <b-row style="margin-top : 10px">
                <pop-up-2></pop-up-2>
            </b-row>
        </div>

        <div class="SliceInside block7" :class="{ hidden : active_el != 8 || !DisplayBlocks || !Display3DPage, fds : active_el == 8}">
            <b-row>
                <div>
                    <h1>Our vision.</h1>
                </div>
            </b-row>
            <b-row>
                <div>
                    <p>The global market is driven by environmental challenges moving from globalisation to a multipolar world. Consumers are demanding more diversity in terms of products but also in terme of brands identity and <span style="color: #f8a028;">experiences</span>. <br></p>
                    <p>People want real alternatives that are more <span style="color: #f8a028;">transparent</span>, more <span style="color: #f8a028;">local</span>, more <span style="color: #f8a028;">sustainable</span> and still as much special. <br> <br></p>
                </div>
            </b-row>
            <b-row>
                <div>
                    <p> At A-Creation, we count world-class talents in e-commerce, logistics, blockchain, business strategy, Data science, Machine learning, and more. We create products, experiences, brands, and ultimately businesses.
                        <br> We are a your bridge from <span style="color: #f8a028;">imagination</span> to <span style="color: #f8a028;">reality</span>.</p>
                </div>
            </b-row>
        </div>

        <div class="SliceInside block9" :class="{ hidden : active_el != 9 || !DisplayBlocks || !Display3DPage, fds : active_el == 9}">
            <b-row>
                <div>
                    <img src="illustrations/undraw_To_the_stars_qhyy.svg" alt="" class="illustrationA">
                </div>
            </b-row>

            <b-row>
                <div>
                    <a class="bouton" href="https://k5m6hakbfp1.typeform.com/to/jz0WxJ8x" target="_blank" rel="noopener noreferrer"> <strong>Start Now</strong></a>
                </div>
            </b-row>
            <b-row>
                <div>
                    <p class="bouton-text"> <br> We take care of everything.</p>
                </div>
            </b-row>
        </div>

        <div class="SliceInside block10" :class="{ hidden : active_el != 10 || !Display3DPage , fds : active_el == 10}">
            <b-row>
                <div>
                    <h1>A-Creation.</h1>
                    
                    <h6><strong><u>General enquiries</u></strong></h6>
                    <br>
                    <p>contact@ananas-creation.com</p>
                    
                    <p>Contact us on <a href="https://www.instagram.com/ananas.creations" target="_blank" rel="noopener noreferrer"><span style="color: #E6E3E3;">instagram</span></a>.</p>

                    <hr class="dashed">

                    <p>© All rights reserved, A-CREATION.</p>
                    <br>

                </div>
            </b-row>
        </div>

        <ul class="dots" :class="{hidden : !Display3DPage}">
            <li :class="{ active : active_el == li.id }" v-for="li in lista" :key="li.id"><a></a></li>
        </ul>

        <div id="Back-to-top-Head" class="Bottom-Block floating" :class="{ hidden : active_el != 10 || !Display3DPage , iphone: isSafari}">
            <div id="Back-to-top" v-on:click="active_el=1"><img src="arrow_up.png"></div>
        </div>
        <div id="scroll-down" class="Bottom-Block floating" :class="{ hidden : active_el != 1 || !Display3DPage , iphone: isSafari} " >
            <p>scroll down</p>
            <div><img src="arrow_down.png"></div>
        </div>
    </div>
    <div v-show="Display3DPage">
        <div class="desktop-config">
            <div class="slice-top">
                <div class="button_cont" align="cen ter"><a class="button_next_back" v-if="active_el > 1" v-on:click="active_el -= 1" draggable="false">Back</a></div>
            </div>
            <br>
            <br>
            <div class="slice-bottom">
                <div class="button_cont" align="center" ><a class="button_next_back" v-if="active_el < 10" v-on:click="active_el += 1" draggable="false">Next</a></div>
            </div>
        </div>
    </div>

</div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/three.js/r128/three.min.js"></script>
<script>
import * as three from 'three'
import {
    GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {
    DRACOLoader
} from 'three/examples/jsm/loaders/DRACOLoader.js';

import PopUp4 from '../components/PopUp4.vue';
import PopUp3 from '../components/PopUp3.vue';
import PopUp2 from '../components/PopUp2.vue';
import PopUp1 from '../components/PopUp1.vue';
// import Carousel from '../components/CarouselMarques.vue'
import LoadingPage from './LoadingPage.vue';
// import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
//import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper.js';
/* eslint-disable no-unused-vars */

export default {
    components: {
        PopUp1,
        PopUp2,
        PopUp3,
        PopUp4,
        // Carousel,
        LoadingPage
    },
    name: 'ThreeTest',
    data() {
        return {
            lista: [{
                "id": "1"
            }, {
                "id": "2"
            }, {
                "id": "3"
            }, {
                "id": "4"
            }, {
                "id": "5"
            }, {
                "id": "6"
            }, {
                "id": "7"
            }, {
                "id": "8"
            }, {
                "id": "9"
            }, {
                "id": "10"
            }],
            renderStatus: 0,
            active_el: 1,
            camera: null,
            scene: null,
            renderer: null,
            loaded: 0,
            move: 0,
            DisplayBlocks: false,
            xDown: null,
            yDown: null,
            Display3DPage: false,
            isLast: false,
            isSplitted: false,
            isSafari:false,
            animationIsRunning: false,
            meshes: [],
            material: null,
            CameraNextPos: 0,
            RotationCoef: 2,
            particlesMesh: null,
            Objects: [{
                    name: "leaves.gltf",
                    offset: 0,
                    mesh: null,
                    isExpanded: false,
                    SplittedCamera: 0,
                    PCSplittedCamera: 0,
                    Space: -4,
                    translation: 0,
                    SplitStatus: 0,
                },
                {
                    name: "slice1.gltf",
                    offset: 0,
                    mesh: null,
                    isExpanded: false,
                    SplittedCamera: 0,
                    PCSplittedCamera: 0,
                    Space: -4,
                    translation: 0,
                    SplitStatus: 0,
                },
                {
                    name: "slice2.gltf",
                    offset: 0.34,
                    mesh: null,
                    isExpanded: false,
                    SplittedCamera: -1.5,
                    PCSplittedCamera: -1.5,
                    Space: -3.2,
                    translation: 0,
                    SplitStatus: 0,
                },
                {
                    name: "slice3.gltf",
                    offset: 0.66,
                    mesh: null,
                    isExpanded: false,
                    SplittedCamera: -1.7,
                    PCSplittedCamera: -2.2,
                    Space: -3.8,
                    translation: 0,
                    SplitStatus: 0,
                },
                {
                    name: "slice4.gltf",
                    offset: 0.988,
                    mesh: null,
                    isExpanded: false,
                    SplittedCamera: -2.2,
                    PCSplittedCamera: -2.7,
                    Space: -3,
                    translation: 0,
                    SplitStatus: 0,
                },
                {
                    name: "slice5.gltf",
                    offset: 1.31,
                    mesh: null,
                    isExpanded: false,
                    SplittedCamera: -3.5,
                    PCSplittedCamera: -4,
                    Space: -4.7,
                    translation: 0,
                    SplitStatus: 0,
                },
                {
                    name: "slice6.gltf",
                    offset: 1.63,
                    mesh: null,
                    isExpanded: false,
                    SplittedCamera: -4.1,
                    PCSplittedCamera: -4.6,
                    Space: -4,
                    translation: 0,
                    SplitStatus: 0,
                },
                {
                    name: "slice7.gltf",
                    offset: 1.97,
                    mesh: null,
                    isExpanded: false,
                    SplittedCamera: -4.7,
                    PCSplittedCamera: -5.2,
                    Space: -4.7,
                    translation: 0,
                    SplitStatus: 0,
                },
                {
                    name: "slice8.gltf",
                    offset: 2.205,
                    mesh: null,
                    isExpanded: false,
                    SplittedCamera: -5.2,
                    PCSplittedCamera: -5.7,
                    Space: -3.5,
                    translation: 0,
                    SplitStatus: 0,
                },
                {
                    name: "slice9.gltf",
                    offset: 2.49,
                    mesh: null,
                    isExpanded: false,
                    SplittedCamera: -6,
                    PCSplittedCamera: -6.5,
                    Space: -4.2,
                    translation: 0,
                    SplitStatus: 0,
                },
            ]
        }
    },
    watch: {
        loaded: function (val) {
            if (val == 10) {
                this.FirstRenderAnanas();
            }
        },
        active_el: function (val) {
            this.DisplayBlocks = false;
            if (this.active_el == 10) {
                if (this.isLast == false) {
                    this.resetAnanas();
                    this.isLast = true
                    this.camera.position.y = -6.5
                    this.Objects[9].translation = 0.75;
                    this.render()
                }
            } else if (this.active_el > 1) {
                var maxWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                this.CameraNextPos = this.Objects[this.active_el].SplittedCamera;
                if (maxWidth > 1024) {
                    this.CameraNextPos = this.Objects[this.active_el].PCSplittedCamera
                }
                console.log(this.CameraNextPos)
                console.log(this.camera.position.y)
                this.isLast = false
                if (this.CameraNextPos < this.camera.position.y) {
                    console.log("down")
                    this.setSplittedCameraDown();
                } else {
                    console.log("up")
                    this.setSplittedCameraUp();
                }
                if (!this.isSplitted) {
                    this.SplitAnanas();
                } else {
                    this.moveAnanasParts();
                }
            } else {
                this.resetAnanas();
                this.resetCamera();
            }
        },
        Display3DPage: function (val) {

            if (this.Display3DPage) {
                console.log("displaying correctly")
                this.animateAnanasAppear();

                window.addEventListener('wheel', this.handleScroll);
                document.addEventListener('touchstart', this.handleTouchStart, false);
                document.addEventListener('touchmove', this.handleTouchMove, false);
            }
        }
    },
    methods: {
        init: async function () {
            let container = document.getElementById('container');
            const canvas = document.querySelector('canvas.webgl')

            this.renderer = new three.WebGLRenderer({
                canvas: canvas,
                antialias: false,
                powerPreference: "high-performance",
                alpha: true
            });
            this.renderer.setPixelRatio(window.devicePixelRatio);
            console.log(navigator.userAgent)
            //iphone mode
            if (this.DetermineBrowser() == "Safari") {

                //original iphone mode
                this.renderer.setSize(container.offsetWidth, container.offsetHeight - 100);
                this.isSafari = true;
            } else {
                this.renderer.setSize(container.offsetWidth, container.offsetHeight);
                
            }
            this.renderer.toneMapping = three.ACESFilmicToneMapping;
            this.renderer.toneMappingExposure = 1;
            this.renderer.outputEncoding = three.sRGBEncoding;
            //this.renderer.autoclear = false;
            // this.renderer.ba
            container.appendChild(this.renderer.domElement);

            this.camera = new three.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 10, 20000);
            this.camera.position.set(0, 8, 41);

            let scene = new three.Scene();
            scene.background = null;
            this.scene = scene;
            let self = this;
            this.renderer.setClearColor(0x000000, 0); // the default






            // Objects
            const geometry = new three.TorusGeometry( .7, .2, 16, 100 );
            const particlesGeometry = new three.BufferGeometry;
            const particlesCnt = 3000;
            const posArray = new Float32Array(particlesCnt * 3);
            for(let i = 0; i < particlesCnt * 3; i++) {
                // posArray[i] = Math.random() - 0.5
                posArray[i] = (Math.random() - 0.5) * (Math.random() * 50)
            }
            particlesGeometry.setAttribute('position', new three.BufferAttribute(posArray, 3))
            // Materials
            const material = new three.PointsMaterial({
                size: 0.005,
                color: 0xffffff
            })
            const particlesMaterial = new three.PointsMaterial({
                size: 0.05,
                color: 0xffffff
            })
            // Mesh
            let particlesMesh = new three.Points(particlesGeometry, particlesMaterial)
            particlesMesh.position.z = -6

            scene.add(particlesMesh);

            /**
             * Animate
             */
            document.addEventListener('mousemove', animateParticles)
            let mouseX = 0
            let mouseY = 0
            function animateParticles(event){
                mouseX = event.clientX
                mouseY = event.clientY
            }
            const clock = new three.Clock()
            const tick = () =>
            {
                const elapsedTime = clock.getElapsedTime()
                // Update objects
                particlesMesh.rotation.y = -.1 * elapsedTime
                if (mouseX > 0) {
                    particlesMesh.rotation.x = -mouseY * (elapsedTime * 0.000005)
                    particlesMesh.rotation.y = mouseX * (elapsedTime * 0.000005)     
                }

                // Call tick again on the next frame
                window.requestAnimationFrame(tick)
            }
            tick()







            var hemilight = new three.HemisphereLight(0xffffbb, 0x080820, 0.05);
            scene.add(hemilight);

            const directionalLight = new three.DirectionalLight(0xffffff, 0.9);
            directionalLight.position.x = -0.15;
            directionalLight.position.y = 0.2;
            directionalLight.position.z = 0.866;
            directionalLight.castShadow = false
            scene.add(directionalLight);

            const light1 = new three.AmbientLight(0xffffff, 0.05);
            light1.name = 'ambient_light';
            scene.add(light1);
            const loader = new GLTFLoader()
            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderPath('draco/gltf/');
            dracoLoader.preload()
            loader.setDRACOLoader(dracoLoader);
            var time = 200;
            self.Objects.forEach(element => {
                setTimeout(() => {
                    loader.load(element.name, function (gltf) {
                        gltf.scene.rotation.x = 0;
                        gltf.scene.position.y += element.offset + 0.75;
                        element.mesh = gltf.scene;
                        element.mesh.castShadow = false;
                        element.mesh.layers.enable(self.loaded)
                        //directionalLight.target = gltf.scene;
                        self.loaded += 1
                    });
                }, time);
                time += 200;
            });
            this.renderer.compile(self.scene, self.camera)
        },
        render: function () {
            this.renderer.render(this.scene, this.camera);

        },
        AnanasRotation: function () {
            var anim = requestAnimationFrame(this.AnanasRotation);
            this.Objects.forEach(element => {
                element.mesh.rotation.y += (0.003 * this.RotationCoef)
            });
            this.render()

        },
        FirstRenderAnanas: function () {
            let time = 100;

            for (let index = 0; index < 10; index++) {
                setTimeout(() => {
                    this.scene.add(this.Objects[index].mesh);
                    this.render()
                    this.renderStatus += 1;
                }, time);
                time += 200;
            }
        },


        animateAnanasAppear: function () {
            var anim = requestAnimationFrame(this.animateAnanasAppear);

            if (this.loaded >= 10) {
                if (this.camera.position.y > 0) {
                    this.camera.position.y -= 0.2;
                }
                if (this.camera.position.z > 12) {
                    this.camera.position.z -= 0.6;
                }
                if (this.camera.position.y <= 0 && this.camera.position.z <= 12) {
                    cancelAnimationFrame(anim);
                    //console.log("test")
                    this.camera.position.y = 0
                    this.camera.position.z = 12
                    this.AnanasRotation()
                }

                this.render()
            }
        },
        activate: function (el) {
            this.active_el = el;
        },
        SplitAnanas: function () {
            var nb = 0;
            this.animationIsRunning = true

            if (!this.isSplitted) {
                var anim = requestAnimationFrame(this.SplitAnanas);

                this.animationIsRunning = true
                this.Objects.forEach(element => {
                    if (element.SplitStatus < element.offset) {
                        element.mesh.position.y -= 0.1

                        //element.isExpanded = true;
                        element.SplitStatus += 0.1
                    } else {
                        nb += 1;
                    }
                });
                this.render()

                // console.log(nb)
                if (nb >= this.Objects.length) {
                    this.isSplitted = true
                    cancelAnimationFrame(anim)
                    this.moveAnanasParts();
                    this.animationIsRunning = false;
                }

            }
        },
        UNSplitAnanas: function () {
            var nb = 0;
            //this.animationIsRunning = true

            if (this.isSplitted) {
                var anim = requestAnimationFrame(this.UNSplitAnanas);
                //this.animationIsRunning = true

                this.Objects.forEach(element => {
                    if (element.translation <= element.offset) {
                        element.mesh.position.y += 0.1

                        //element.isExpanded = true;
                        element.translation += 0.1
                    } else {
                        nb += 1;
                    }
                });
                this.render()

                // console.log(nb)
                if (nb >= this.Objects.length) {
                    this.isSplitted = false
                    cancelAnimationFrame(anim)
                    //this.moveAnanasParts();
                    this.Objects.forEach(element => {
                        element.mesh.position.y = element.offset + 0.75;
                    });
                    this.render()

                    this.animationIsRunning = false;

                }

            }
        },
        setSplittedCameraDown: function () {
            var anim = requestAnimationFrame(this.setSplittedCameraDown);
            // console.log(this.camera.position.y )

            if (this.camera.position.y > this.CameraNextPos) {
                this.camera.position.y = this.camera.position.y - 0.1
                //console.log(this.camera.position.y)
            } else {
                console.log("ended down ")

                cancelAnimationFrame(anim)
            }
        },
        setSplittedCameraUp: function () {
            var anim = requestAnimationFrame(this.setSplittedCameraUp);
            // console.log(this.camera.position.y )
            if (this.camera.position.y < this.CameraNextPos) {
                this.camera.position.y += 0.1
            } else {
                console.log("ended up")
                cancelAnimationFrame(anim)
            }
        },
        resetCamera: function () {
            var anim = requestAnimationFrame(this.resetCamera);
            if (this.camera.position.y < 0) {
                this.camera.position.y += 0.1
            } else {
                this.camera.position.y = 0
                cancelAnimationFrame(anim)
            }
        },
        resetAnanas: function () {
            var anim = requestAnimationFrame(this.resetAnanas);
            this.animationIsRunning = true;
            var j = 1;
            while (j < this.Objects.length) {
                if (this.Objects[j].translation < 0) {
                    this.Objects[j].mesh.position.y += 0.1;
                    this.Objects[j].translation += 0.1;
                }
                j++
            }
            this.move -= 0.1;
            this.render()
            let limit = 0
            if (this.Objects[this.active_el] != undefined) {
                limit = this.Objects[this.active_el].Space;
            }

            if (this.move <= limit) {
                this.animationIsRunning = false
                cancelAnimationFrame(anim)
                this.move = 0
                this.Objects.forEach(element => {

                    element.mesh.position.y = 0.75
                });
                if (this.active_el != 10)
                    this.UNSplitAnanas()
            }
        },
        moveAnanasParts: function () {
            var anim = requestAnimationFrame(this.moveAnanasParts);
            var i = this.active_el;
            var movementSpeed = 0.13; /// Change split speed

            this.animationIsRunning = true;
            while (i < this.Objects.length) {
                if (this.Objects[i].translation > this.Objects[this.active_el].Space + 0.1) {
                    this.Objects[i].mesh.position.y -= movementSpeed;
                    this.Objects[i].translation -= movementSpeed;
                } else if (this.Objects[i].translation < this.Objects[this.active_el].Space - 0.1) {
                    //console.log("up")
                    this.Objects[i].mesh.position.y += movementSpeed;
                    this.Objects[i].translation += movementSpeed;
                }
                i++
            }
            let j = 1;
            let ratio = this.Objects[this.active_el].Space / this.Objects[this.active_el - 1].Space
            while (j < this.active_el) {
                if (this.Objects[j].translation < 0) {
                    this.Objects[j].mesh.position.y += movementSpeed;
                    this.Objects[j].translation += movementSpeed;
                }
                j++
            }
            this.move -= movementSpeed;
            this.render()

            let limit = this.Objects[this.active_el].Space;

            if (this.move <= limit) {
                this.DisplayBlocks = true;
            }
            if (this.move <= limit * 2) {
                this.animationIsRunning = false
                cancelAnimationFrame(anim)
                this.move = 0
            }
        },
        handleScroll(e) {
            console.log(this.Objects[1].mesh.position.y)
            /*
            if (e.deltaY > 0) {
                this.RotationCoef = 20;
                setTimeout(() => {
                    this.RotationCoef = 9;
                }, 100);
            } else {
                this.RotationCoef = -20;
                setTimeout(() => {
                    this.RotationCoef = -9;
                }, 100);
            }
            */
            if (e.deltaX < 0) {
                this.RotationCoef = 3;
                
                
            } else {
                this.RotationCoef = -3;
                
            }
            if (e.deltaY < 0) {
                this.RotationCoef = 3;
                
                
            } else {
                this.RotationCoef = -3;
                
            }
            /*
            if (!this.animationIsRunning) {
                if (e.deltaY < 0) {
                    if (this.active_el > 1) {
                        console.log('scrolling up');
                        this.active_el -= 1
                    }
                    //document.getElementById('status').textContent= 'scrolling up';
                } else if (e.deltaY > 0) {
                    if (this.active_el < 10) {
                        console.log('scrolling down');
                        this.active_el += 1
                    }
                    //console.log('scrolling down');
                    //document.getElementById('status').textContent= 'scrolling down';
                }

            }
            */
        },
        getTouches(evt) {
            return evt.touches || // browser API
                evt.originalEvent.touches; // jQuery
        },
        handleTouchStart(evt) {
            const firstTouch = this.getTouches(evt)[0];
            this.xDown = firstTouch.clientX;
            this.yDown = firstTouch.clientY;
        },
        handleTouchMove(evt) {
            if (!this.xDown || !this.yDown) {
                return;
            }
            var xUp = evt.touches[0].clientX;
            var yUp = evt.touches[0].clientY;

            var xDiff = this.xDown - xUp;
            var yDiff = this.yDown - yUp;
            /*
            if (Math.abs(yDiff) > 1) {
                this.RotationCoef = yDiff;
                if (this.RotationCoef > 1) {
                    this.RotationCoef = 1.3;
                } else {
                    this.RotationCoef = -1.3;
                }
            }
            */
            if (Math.abs(xDiff) > 7) {
                this.RotationCoef = xDiff;
                if (this.active_el==1){
                    if (this.RotationCoef > 1) {
                        this.RotationCoef = -30;
                    } else {
                        this.RotationCoef = 30;
                    }
                } else {
                    if (this.RotationCoef > 1) {
                        this.RotationCoef = -15;
                    } else {
                        this.RotationCoef = 15;
                    }
                }

            }
            if (Math.abs(yDiff) > 5) {
            
                if (yDiff < 0) {
                    if (this.active_el > 1) {
                        this.active_el -= 1
                    }
                } else {
                    if (this.active_el < 10) {
                        this.active_el += 1
                    }
                }
                
            }
            /* reset values */
            this.xDown = null;
            this.yDown = null;
        },
        DetermineBrowser: function () {
            if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
                return('Opera');
            } else if (navigator.userAgent.indexOf("Chrome") != -1) {
                return('Chrome');
            } else if (navigator.userAgent.indexOf("Safari") != -1) {
                return('Safari');
            } else if (navigator.userAgent.indexOf("Firefox") != -1) {
                return('Firefox');
            } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
            {
                return('IE');
            } else {
                return('unknown');
            }

        }

    },
    mounted() {
        this.init();
        //this.animateAnanasAppear();
    }
}
</script>

<style lang="scss" scoped>
@import "./src/style/LandingPage.scss";
</style>
