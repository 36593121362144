<template>
    <div class="loadingcontainer">

        <b-row>
            <b-col> 
              <a class="bouton"  v-if="status!=10 || renderer != 10">{{status  | loading(renderer)}}</a>
              <a  class="bouton" id="bouton-pret" v-else v-on:click="isNinja = true; $emit('change-page');"><strong>ENTER</strong>  </a>
            </b-col>
        </b-row>
                <b-row>
            <b-col> 
              <br>
            <p><strong>Something cool is loading..</strong></p>
            </b-col>
        </b-row>
    </div>
</template>


<script>
export default {
  name: 'LoadingPage',
  props: [
    'status',
    'renderer',
  ],
  components : {
  },
  filters: {
    loading: function (loader) {
      if (loader*10 >= 90 ) {
        return 99;
      }
      else return loader*10;
    }
  }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../style/LoadingPage.css">



</style>
